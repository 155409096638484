import ImgWebp from "assets/shared/img/ts.webp";
import i18next from "i18next";

import { ERROR_STATUS_UNPROCESSABLE, TYPE_PHOTO } from "shared/constants";
import {
  ACTIVITY_TYPE_CHAT,
  ACTIVITY_TYPE_FAVORITE,
  ACTIVITY_TYPE_LIKE,
  ACTIVITY_TYPE_LIVE_STREAM,
  ACTIVITY_TYPE_MAIL,
  ACTIVITY_TYPE_VISITOR,
  ACTIVITY_TYPE_WINK,
} from "shared/constants/actiity";
import {
  BILLING_TYPE_COOKIE_NAME,
  COOKIE_IS_WEBP_COMPATIBLE,
  STORAGE_SEON_SESSION_ID,
  TEST_USER_COOKIE_KEY,
} from "shared/constants/cookie";
import { monthFull } from "shared/constants/date";
import {
  UPLOAD_IMAGE_FILE_FORMATS,
  UPLOAD_MAX_IMAGE_FILE_SIZE,
  UPLOAD_VIDEO_FILE_FORMATS,
} from "shared/constants/files";
import { uploadSectionText } from "shared/constants/media";
import { OPTION_ENABLED } from "shared/constants/options";
import { SPLIT_TEST_STATUS_ACTIVE } from "shared/constants/split";
import { BILLING_TYPE_CHAT_PER_MINUTE } from "shared/constants/user";

import { deleteCookie, existCookie, getCookie, setCookie } from "./cookie";

/**
 * Check value empty or not
 * @param {*} value
 * @returns {boolean}
 */
export const isUndefined = (value) => {
  if (typeof value === "undefined" || isNull(value)) {
    return true;
  }

  return false;
};

/**
 * Value equal null or not
 * @param {*} value
 * @returns {boolean}
 */
export const isNull = (value) => {
  if (value === null) {
    return true;
  }

  return false;
};

/**
 * Escape tags
 * @param {string} unsafe
 * @returns {string}
 */
export function escapeText(unsafe) {
  return !unsafe
    ? unsafe
    : unsafe
        .replace(/&amp;amp;/g, "&")
        .replace(/&amp;/g, "&")
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
        .replace(/&quot;/g, '"')
        .replace(/&#039;/g, "'");
}

/**
 * Get error response object
 * @param {Object|null} response Api response error object
 * @param {string} defaultErrorText Default error text
 * @returns {{error: boolean, message: string}}
 */
export function getResponseError(response, defaultErrorText = "") {
  const error = {
    error: true,
    message: defaultErrorText,
  };

  if (
    +response?.status === ERROR_STATUS_UNPROCESSABLE &&
    Array.isArray(response?.data?.errors)
  ) {
    error.message = "";
    response.data.errors.forEach((item) => {
      if (item?.message) {
        error.message += `${item.message} `;
      }
    });
  }

  return error;
}

/**
 * Set seon session cookie
 *
 * @param {Number} userUid User id
 * @param {Number} iteration Iteration
 */
export function setSeonSessionId(userUid, iteration = 0) {
  if (iteration >= 9) {
    return;
  }

  if (typeof window.seon === "undefined") {
    setTimeout(() => {
      setSeonSessionId(userUid, ++iteration);
    }, 1000);

    return;
  }

  try {
    const userSessionId = `${userUid}_${new Date().getTime()}`;
    const data = localStorage.getItem(STORAGE_SEON_SESSION_ID);

    if (existCookie(STORAGE_SEON_SESSION_ID) && data) {
      setCookie(
        STORAGE_SEON_SESSION_ID,
        getCookie(STORAGE_SEON_SESSION_ID),
        30,
        true
      );
      return;
    } else if (!existCookie(STORAGE_SEON_SESSION_ID) && data) {
      const { id } = JSON.parse(data);
      setCookie(STORAGE_SEON_SESSION_ID, id, 30, true);
      return;
    }

    window.seon.config({
      session_id: userSessionId,
      audio_fingerprint: true,
      canvas_fingerprint: true,
      webgl_fingerprint: true,
      onSuccess: function () {
        window.seon.getBase64Session((data) => {
          if (data) {
            localStorage.setItem(
              STORAGE_SEON_SESSION_ID,
              JSON.stringify({
                id: userSessionId,
                session: data,
              })
            );
            setCookie(STORAGE_SEON_SESSION_ID, userSessionId, 30, true);
          }
        });
      },
    });
  } catch (e) {
    console.error(e.toString());
  }
}

/**
 * Get full image path with cdn
 * @param {string} img Image url
 * @param {string} property Object attribute
 * @param {number} newsUid news item uid
 * @return {*}
 */
export function getPhotoUrl(img, property = "", newsUid = 0) {
  if (!img) {
    return img;
  }

  if (img.indexOf("https://") >= 0 || img.indexOf("http://") >= 0) {
    return img;
  }
  const cdn = import.meta.env.VITE_APP_MEDIA_CDN_URL;
  const imgUrl =
    cdn.substring(cdn.length - 1) === "/"
      ? cdn.substring(0, cdn.length - 1)
      : cdn;

  return (
    imgUrl +
    (newsUid
      ? `/news/${newsUid}/${property === "thumbnail" ? "thumb" : property}/`
      : "") +
    img
  );
}

/**
 * Get picture according to webp format compatible
 * @param {Object} imageObject Image object
 * @param {string} property Object attribute
 * @param {number} newsUid news item uid
 * @return {string|null} Get full path image url
 */
export function getPictureWebpCompatible(imageObject, property, newsUid = 0) {
  if (!imageObject || typeof imageObject[property] === "undefined") {
    return null;
  }

  let img = getPhotoUrl(imageObject[property]);

  if (imageObject.webp && window.isWebpCompatible) {
    img = imageObject[property].replace(".jpg", ".webp");
  }
  return newsUid ? getPhotoUrl(img, property, newsUid) : getPhotoUrl(img);
}

/**
 * Check browser version on compatible with webp image format
 */
export const checkBrowserWithWebpCompatible = () => {
  if (existCookie(COOKIE_IS_WEBP_COMPATIBLE)) {
    window.isWebpCompatible = true;
    return;
  }
  try {
    window.isWebpCompatible = false;

    const img = new Image();
    img.onload = function () {
      if (img.height > 0 && img.width > 0) {
        window.isWebpCompatible = true;
        setCookie(COOKIE_IS_WEBP_COMPATIBLE, true, 30);
      }
    };
    img.src = ImgWebp;
  } catch (e) {
    console.log(e.toString());
  }
};

/**
 * Drop haotjar cookie rha crash apploication
 */
export const dropHotjarCrashCookie = () => {
  const prefix = "_hj";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(prefix) === 0) {
      const cookieParts = c.split("=");
      const name = cookieParts[0];

      if (!name) {
        return;
      }

      const value = c.replace(name + "=", "");

      if (typeof value === "string") {
        try {
          const json = JSON.parse(value);

          if (typeof json === "object" && Object.keys(json).length > 0) {
            deleteCookie(name);
          }
        } catch (e) {
          console.log(e.toString());
        }
      }
    }
  }
};

/**
 * Check browser is Safari
 * @returns {boolean}
 */
export const isSafari = () => {
  return (
    navigator?.vendor?.includes("Apple") &&
    !navigator?.userAgent?.includes("CriOS") &&
    !navigator?.userAgent?.includes("FxiOS")
  );
};

/**
 * Check is current device from iOs family
 * @returns {boolean}
 */
export const isIOS = () => {
  return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
};

/**
 * Check browser is current user use Chrome browser on iOs device
 * @return {boolean}
 */
export const isIOSAndChrome = () => {
  if (!isIOS()) {
    return false;
  }

  return (
    navigator.vendor &&
    navigator.vendor.indexOf("Apple") > -1 &&
    navigator.userAgent &&
    navigator.userAgent.indexOf("CriOS") > -1
  );
};

/**
 * Check is enabled notification for user profile according to activity type
 * @param {Object} profileNotifySetting Profile notification settings
 * @param {number} type Activity type
 * @returns {boolean}
 */
export const isEnabledNotifySetting = (profileNotifySetting, type) => {
  let enabled = false;

  switch (type) {
    case ACTIVITY_TYPE_LIKE:
    case ACTIVITY_TYPE_VISITOR:
    case ACTIVITY_TYPE_LIVE_STREAM:
    case ACTIVITY_TYPE_FAVORITE:
      enabled = profileNotifySetting.notify_activity === OPTION_ENABLED;
      break;
    case ACTIVITY_TYPE_WINK:
      enabled = profileNotifySetting.notify_wink === OPTION_ENABLED;
      break;
    case ACTIVITY_TYPE_MAIL:
      enabled = profileNotifySetting.notify_mail === OPTION_ENABLED;
      break;
    case ACTIVITY_TYPE_CHAT:
      enabled = profileNotifySetting.notify_chat === OPTION_ENABLED;
      break;
    default:
      break;
  }

  return enabled;
};

/**
 * get Chat Date By Time
 * @param {number} time
 * @return {result} Get date by time
 */
export function getDateByTime(time) {
  let result = null;

  try {
    const date = new Date();
    date.setTime(time);
    result =
      date.getDate() +
      " " +
      monthFull[date.getMonth()] +
      " " +
      date.getFullYear();
  } catch (e) {
    console.log(e.toString());
  }

  return result;
}

/**
 * Get time in US format
 * @param {number} time Time in milliseconds
 * @param {string} pm Noon
 * @param {string} am Midnight
 * @return {null|string}
 */
export function getTimeFormatted(time, pm = "PM", am = "AM") {
  let result = null;
  try {
    const date = new Date();
    date.setTime(time);
    const hours = date.getHours() % 12;
    return `${hours > 0 ? hours : 12}:${
      date.getMinutes() >= 10 ? date.getMinutes() : "0" + date.getMinutes()
    } ${date.getHours() >= 12 ? pm : am}`;
  } catch (e) {
    console.log(e.toString());
  }
  return result;
}

/**
 * get Today date
 * @return {result} Get date by today
 */
export function getTodayDate() {
  const time = new Date().getTime();

  return getDateByTime(time);
}

/**
 * get Yesterday date
 * @return {result} Get date by today
 */
export function getYesterdayDate() {
  let time = new Date().getTime();

  time = time - 24 * 60 * 60 * 1000;

  return getDateByTime(time);
}

/**
 * Convert timestamp to relative time
 * @param {number} publishedAt news item published at
 * @param {number} now Time now
 * @return {string} Relative time
 */
export function convertToRelativeTime(publishedAt, now) {
  const msPerMinute = 60 * 1000;
  const msPerHour = msPerMinute * 60;
  const ms2Hours = msPerMinute * 120;
  const msPerDay = msPerHour * 24;
  const ms2Days = msPerDay * 2;
  const msPerWeek = msPerDay * 7;
  const ms2Weeks = msPerWeek * 2;
  const msPerMonth = msPerDay * 31;
  const ms2Months = msPerMonth * 2;

  const elapsed = now - publishedAt;

  if (elapsed < msPerMinute) {
    return "Now";
  } else if (elapsed < msPerHour) {
    return Math.round(elapsed / msPerMinute) + " minutes ago";
  } else if (elapsed < ms2Hours) {
    return "a hour ago";
  } else if (elapsed < msPerDay) {
    return Math.round(elapsed / msPerHour) + " hours ago";
  } else if (elapsed < ms2Days) {
    return "a day ago";
  } else if (elapsed < msPerWeek) {
    return Math.round(elapsed / msPerDay) + " days ago";
  } else if (elapsed < ms2Weeks) {
    return "a week ago";
  } else if (elapsed < msPerMonth) {
    return Math.round(elapsed / msPerWeek) + " weeks ago";
  } else if (elapsed < ms2Months) {
    return "a month ago";
  }
  return "a long time ago";
}

/**
 * Prepare query string from object
 * @param {Object} attributes Request attributes
 * @return {string} Return query string for request
 */
export const prepareQueryString = (attributes) => {
  let query = "";
  let i = 0;

  for (const key in attributes) {
    const value = attributes[key];

    if (value !== null && value !== undefined) {
      query += i <= 0 ? "?" + key + "=" + value : "&" + key + "=" + value;

      i++;
    }
  }

  return query;
};

/**
 * Prepare query string for search also request
 * @param {Array} excludeUid User uid
 * @param {number} limit Limit of records
 * @return {string} Return query string for request
 */
export const prepareSearchExcludeUidString = (excludeUid = [], limit) => {
  if (!excludeUid.length) {
    return `?limit=${limit}`;
  }

  return (
    excludeUid
      .map((uid, i) => {
        return `${i === 0 ? "?" : "&"}exclude_uid[]=${uid}`;
      })
      .join("") + `&limit=${limit}`
  );
};

/**
 * Custom equal method for check props equal mor dip
 * @param {Object} prevProps Previous component props
 * @param {Object} nextProps Current component props
 * @returns {boolean}
 */
export const areDipEqual = (prevProps, nextProps) => {
  let isEqual = true;

  if (Object.keys(nextProps).length !== Object.keys(prevProps).length) {
    return false;
  }

  for (let i = 0; i < Object.keys(nextProps).length; i++) {
    const prop = Object.keys(nextProps)[i];

    if (typeof nextProps[prop] === "function") {
      continue;
    } else if (typeof nextProps[prop] === "object") {
      isEqual =
        JSON.stringify(prevProps[prop]) === JSON.stringify(nextProps[prop]);
    } else {
      isEqual = prevProps[prop] === nextProps[prop];
    }

    if (!isEqual) {
      break;
    }
  }

  return isEqual;
};

/**
 * Get count items per row for mobile version
 * @returns {number}
 */
export const getCountSearchItemsPerRow = () => {
  const COUNT_ITEMS_PER_ROW_SM = 2;
  const COUNT_ITEMS_PER_ROW_MEDIUM = 3;
  const COUNT_ITEMS_PER_ROW_LARGE = 4;
  const COUNT_ITEMS_PER_ROW_X_LARGE = 5;

  return window.innerWidth > 1000
    ? COUNT_ITEMS_PER_ROW_X_LARGE
    : window.innerWidth > 767
      ? COUNT_ITEMS_PER_ROW_LARGE
      : window.innerWidth > 470
        ? COUNT_ITEMS_PER_ROW_MEDIUM
        : COUNT_ITEMS_PER_ROW_SM;
};

/**
 * Get sender link for user email
 * @param {string} email User email
 * @returns {string}
 */
export function getInboxUrl(email) {
  return "//" + email.substring(email.indexOf("@") + 1, email.length);
}

/**
 * Send analytics data to gtm
 * @param {Object} event Object with event data
 * @param {Object|null} optionsObject Custom event object
 * @param {boolean} send Send data to gtm
 */
export function gtmTrack(event, optionsObject = null, send = true) {
  let eventData = {
    event: event,
  };

  if (optionsObject && Object.keys(optionsObject).length > 0) {
    eventData = { ...eventData, ...optionsObject };
  }

  if (typeof window.dataLayer !== "undefined" && send) {
    window.dataLayer.push(eventData);
  }
}

export function checkIfChromeMobile() {
  const ua = navigator?.userAgent ?? "";
  const mobilePattern = /Chrome\/[.0-9]* Mobile/i;
  return ua.indexOf("Android") >= 0 && mobilePattern.test(ua);
}

/**
 * Is push available according to device
 * @param {boolean} onlyChrome Only for chrome browser
 * @returns {boolean|false|boolean}
 */
export function isPushAvailable(onlyChrome = false) {
  if ("Notification" in window) {
    const isChrome = !!window.chrome;
    const isChromeMobile = checkIfChromeMobile();
    const hasNotifyAccess =
      Notification.permission && Notification.permission === "default";

    if (onlyChrome) {
      return hasNotifyAccess && (isChrome || isChromeMobile);
    }

    return hasNotifyAccess;
  }

  return false;
}

/**
 * Get payment page url
 * @param {null|string} backUrl Back url from payment service
 * @param {null|string} cancelUrl Back url after failed pay
 * @return {*}
 */
export function getNotEnoughCreditsUrl(backUrl = null, cancelUrl = null) {
  let url =
    import.meta.env.VITE_APP_PAYMENT_HOST_URL +
    import.meta.env.VITE_APP_PAYMENT_PAGE_URL;

  if (backUrl || cancelUrl) {
    url += "?";
  }

  if (backUrl) {
    url += "backUrl=" + encodeURIComponent(backUrl);
  }

  if (cancelUrl) {
    url += (backUrl ? "&" : "") + "cancelUrl=" + encodeURIComponent(cancelUrl);
  }

  return url;
}

/**
 * Sort object by parameter
 * @param {object} object Object for sort
 * @param {string} parameter Object parameter name
 * @param {boolean} desc Hoe to sort object
 * @return {{}}
 */
export function sortObject(object, parameter, desc = false) {
  const result = {};

  const sorted = Object.keys(object).sort((a, b) => {
    if (desc) {
      return object[b][parameter] - object[a][parameter];
    } else {
      return object[a][parameter] - object[b][parameter];
    }
  });

  for (const key of sorted) {
    if (!isUndefined(object[key])) {
      result[key] = object[key];
    }
  }

  return result;
}

/**
 * Detect if localStorage available
 * @param {string} type
 * @return {*}
 */
function storageAvailable(type) {
  if (!window[type]) {
    return false;
  }

  let storage = window[type];

  try {
    const x = "__storage_test__";
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return (
      e instanceof DOMException &&
      // everything except Firefox
      (e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === "QuotaExceededError" ||
        // Firefox
        e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
      // acknowledge QuotaExceededError only if there's something already stored
      storage &&
      storage.length !== 0
    );
  }
}

/**
 * Set data to localStorage
 * @param {string} key
 * @param {*} data
 */
export function setLocalStorageData(key, data) {
  if (!storageAvailable("localStorage")) {
    return null;
  }

  data = JSON.stringify(data);

  return localStorage.setItem(key, data);
}

/**
 * Get data from localStorage
 * @param {string} key
 */
export function getLocalStorageData(key) {
  if (!storageAvailable("localStorage")) {
    return null;
  }

  let data = localStorage.getItem(key);

  if (data) {
    data = JSON.parse(data);
  }

  return data;
}

/**
 * Delete data from localStorage
 * @param {string} key
 */
export function deleteLocalStorageData(key) {
  if (!storageAvailable("localStorage")) {
    return null;
  }

  return localStorage.removeItem(key);
}

/**
 * Get styles for image
 * @param {*} event
 * @param {number} parentWidth wrap width
 * @param {number} parentHeight wrap height
 * @return {object} styles
 */
export function getImageTileStyleSquare(event, parentWidth, parentHeight) {
  const { naturalWidth, naturalHeight } = event.target;
  const parent = event.target.parentElement;
  let style = {};

  parentWidth = parentWidth ? parentWidth : parent.clientWidth;
  parentHeight = parentHeight ? parentHeight : parent.clientHeight;

  if (naturalWidth < parentWidth && naturalHeight < parentHeight) {
    return {
      left: 50 + "%",
      top: 50 + "%",
      transform: "translate(-50%, -50%)",
    };
  }

  if (naturalWidth > naturalHeight) {
    let left = (naturalWidth - parentWidth) / 2;
    if (naturalWidth > parentWidth) {
      const percent = parentWidth / (naturalHeight / 100);
      left = ((naturalWidth / 100) * percent - parentWidth) / 2;
    }
    style = {
      maxHeight: parentWidth + "px",
      top: 50 + "%",
      transform: "translate(0%, -50%)",
      left: "-" + left + "px",
    };

    return style;
  } else if (naturalHeight > naturalWidth) {
    let top = (naturalHeight - parentWidth) / 2;
    if (naturalHeight > parentWidth) {
      const percent = parentWidth / (naturalWidth / 100);
      top = ((naturalHeight / 100) * percent - parentWidth) / 2;
    }

    style = {
      maxWidth: parentWidth + "px",
      left: 50 + "%",
      transform: "translate(-50%)",
      top: "-" + top + "px",
    };

    return style;
  } else {
    style = {
      maxWidth: parentWidth + "px",
      maxHeight: parentWidth + "px",
      left: 50 + "%",
      top: 50 + "%",
      transform: "translate(-50%, -50%)",
    };

    if (parentWidth < parentHeight) {
      style.maxWidth = parentHeight + "px";
      style.maxHeight = parentHeight + "px";
    }
  }

  return style;
}

/**
 * Check is media file valid
 * @param {object} file
 * @param {number} format type
 * @return {object}
 */
export function isMediaFileValid(file, format) {
  let { size, name } = file;
  const allFormats = UPLOAD_VIDEO_FILE_FORMATS.join(",");

  if (name && name.length > 20) {
    name = name.substring(0, 20) + "...";
  }

  // if (format == API_CONSTANTS.TYPE_VIDEO) {
  //   const extension = file.name.split('.');
  //
  //   if (extension.length <= 0 || API_CONSTANTS.CHAT_UPLOAD_VIDEO_EXTENSION.join('').indexOf(extension[extension.length - 1].toLowerCase()) < 0) {
  //     return {
  //       status: false,
  //       text: ERROR.chatFiles.NOT_VALID_VIDEO_EXT + API_CONSTANTS.CHAT_UPLOAD_VIDEO_EXTENSION.join(', '),
  //     };
  //   }
  //
  //   if (size < API_CONSTANTS.MEDIA_UPLOAD_MIN_VIDEO_FILE_SIZE || size > API_CONSTANTS.MEDIA_UPLOAD_MAX_VIDEO_FILE_SIZE) {
  //     return {
  //       status: false,
  //       text: 'Error! ' + uploadSectionText[API_CONSTANTS.TYPE_VIDEO].sizeDesc.map((item) => {
  //         return item;
  //       }).join('\n'),
  //     };
  //   }
  // } else
  if (format === TYPE_PHOTO) {
    const extension = file.name.split(".");

    if (
      extension.length <= 0 ||
      UPLOAD_IMAGE_FILE_FORMATS.join(",").indexOf(
        extension[extension.length - 1].toLowerCase()
      ) < 0
    ) {
      return {
        status: false,
        text: i18next.t("filesErrors.notValidImgExt"),
      };
    }

    if (size > UPLOAD_MAX_IMAGE_FILE_SIZE) {
      return {
        status: false,
        text: uploadSectionText[TYPE_PHOTO].sizeDesc
          .map((item) => {
            return item;
          })
          .join("\n"),
      };
    }
  } else {
    return {
      status: false,
      text: i18next.t("filesErrors.notValidType", {
        types: allFormats.split(",").join(", "),
      }),
    };
  }

  return {
    status: true,
    mediaType: format,
  };
}

/**
 * Check is user billing type is chat per minute
 *
 * @param {Object} profile User object
 * @return {boolean}
 */
export function isPerMinuteBillingType(profile) {
  const billingType = getCookie(BILLING_TYPE_COOKIE_NAME);

  if (profile?.billing_type === null) {
    return +billingType === BILLING_TYPE_CHAT_PER_MINUTE;
  }

  if (+billingType !== +profile?.billing_type) {
    setCookie(BILLING_TYPE_COOKIE_NAME, +profile?.billing_type, 30, true);
  }

  return +profile?.billing_type === BILLING_TYPE_CHAT_PER_MINUTE;
}

/**
 * Get format date
 * @param {number} time Time microseconds format
 */
export const getDateToFormat = (time) => {
  let result = "";
  let billingDate = new Date(parseInt(time / 1000));
  let billingDayFormat =
    billingDate.getDate() < 10
      ? `0${billingDate.getDate()}`
      : billingDate.getDate();
  result = `${billingDate.toLocaleString("en-US", {
    month: "short",
  })} ${billingDayFormat} ${billingDate.getFullYear()}`;
  return result;
};

/**
 * Get seon session fingerprint
 *
 * @return {null|string}
 */
export function getSeonSessionId() {
  try {
    const data = localStorage.getItem(STORAGE_SEON_SESSION_ID);

    if (!data) {
      return null;
    }

    const { session } = JSON.parse(data);

    return session ?? null;
  } catch (e) {
    console.error(e.toString());
  }
  return null;
}

/**
 * Set Google client for Google Pay on OneClick
 * @param {number} iteration Count retry for upload
 */
export function setGoogleClient(iteration = 0) {
  const isTest = getCookie(TEST_USER_COOKIE_KEY);
  let googlePay;
  try {
    // eslint-disable-next-line
    googlePay = !!google;
  } catch (err) {
    googlePay = false;
  }

  if (!window.googlePayClient && googlePay) {
    // eslint-disable-next-line
    window.googlePayClient = new google.payments.api.PaymentsClient({
      environment: isTest ? "TEST" : "PRODUCTION",
    });
  } else if (iteration < 10 && !window.googlePayClient) {
    iteration++;
    setTimeout(() => {
      setGoogleClient(iteration);
    }, 1000);
  }
}

/**
 * Get token verification user
 * @param {string} name
 * @param {null|string} url parameter Url
 */
export const getParameterByName = (name, url = null) => {
  if (!url) url = window.location.href;
  // eslint-disable-next-line
  name = name.replace(/[\[\]]/g, "\\$&");
  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};

/**
 * Check if split test is required
 * @param {Object} profile
 * @param {null|string} splitId
 */
export const isSplitTestRequired = (profile, splitId) => {
  const split = profile?.split ?? [];

  return split.some(
    (split) =>
      split?.uid === +splitId && +split?.status === SPLIT_TEST_STATUS_ACTIVE
  );
};

/**
 * Get full gift image path with cdn
 * @param {string} img Image url
 * @param {string} size
 * @return {*}
 */
export function getGiftImageUrl(img, size = "s") {
  if (!img) {
    return img;
  }

  if (img.indexOf("https://") >= 0 || img.indexOf("http://") >= 0) {
    return img;
  }
  const cdn = import.meta.env.VITE_APP_MEDIA_CDN_URL;
  const imgUrl =
    cdn.substring(cdn.length - 1) === "/"
      ? cdn.substring(0, cdn.length - 1)
      : cdn;

  return imgUrl + `/virtual-gift/${size}/` + img;
}

/**
 * Add spaces to format number view
 * @param {string} value
 * @return {string}
 */
export function addSpaces(value) {
  const remainder = value.length % 3;
  return (
    value.substr(0, remainder) +
    value.substr(remainder).replace(/(\d{3})/g, " $1")
  ).trim();
}

/**
 * Is env value true
 * @param {boolean|string} value
 * @returns {boolean}
 */
export const isTrue = (value) => {
  return typeof value === "boolean" ? value : /^true$/i.test(value);
};
