import {
  getSizeInMb,
  UPLOAD_MAX_IMAGE_FILE_SIZE,
  UPLOAD_MAX_VIDEO_FILE_SIZE,
} from "./files";
import { TYPE_PHOTO, TYPE_VIDEO } from "./index";

export const MEDIA_SOURCE_PROFILE = "profile";
export const MEDIA_SOURCE_COMMUNICATION = "communication";
export const MEDIA_UPLOAD_NOT_VERIFIED = 0;
export const MEDIA_UPLOAD_VERIFIED = 1;

export const PHOTO_TYPE_AVATAR = "PHOTO_TYPE_AVATAR";

export const uploadSectionText = {
  [TYPE_PHOTO]: {
    type: "photo",
    sizeDesc: [
      `Maximum file size ${getSizeInMb(
        UPLOAD_MAX_IMAGE_FILE_SIZE
      ).toString()}Mb`,
    ],
  },
  [TYPE_VIDEO]: {
    type: "video",
    sizeDesc: [
      // "Minimum file size " +
      // API_CONSTANTS.MEDIA_UPLOAD_MIN_VIDEO_FILE_SIZE / 1048576 + "Mb " +
      `Maximum file size ${getSizeInMb(
        UPLOAD_MAX_VIDEO_FILE_SIZE
      ).toString()}Mb`,
    ],
  },
};
